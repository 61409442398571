import React, { useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { XIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/react/solid";
import ReactSwipeEvents from "react-swipe-events";

const Lightbox = ({ project, descriptions, mainImage = "", alignCenter = false, className = "", biggerPreview = false }) => {
    const overlayDiv = useRef();
    const [currentImage, setCurrentImage] = useState({
        image: null,
        description: "",
        index: 0,
    });
    const previewLimit = 3;

    // read all images
    const query = useStaticQuery(graphql`
    query {
          images: allFile(
              filter: {sourceInstanceName: {eq: "project"}}
              sort: {fields: name, order: ASC}
            ) {
            nodes {
              name
              sourceInstanceName
              relativeDirectory
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
    }
    `);

    const getImageByAttr = (images, target, by = "name") => {
        let ret = [];
        images.forEach((image, i) => {
            if ((by === "name" && image.name === target) || (by === "index" && i === target)) {
                ret = [image, i];
                return;
            }
        });
        return ret;
    };

    // filter images by directory name that has to be same as project slug name
    const projectImages = query.images.nodes.filter(n => n.relativeDirectory === project && n.childImageSharp !== null).map(n => { n.description = descriptions[n.name]; return n; });
    let projectThumbnails = [...projectImages];
    const mainImageData = mainImage !== "" && mainImage !== null ? getImageByAttr(projectThumbnails, mainImage)[0] : null;
    // skip image from thumbnails if already used as main
    if (mainImageData) {
        projectThumbnails = projectThumbnails.filter(n => n.name !== mainImageData.name);
    }

    if (projectImages.length === 0) {
        return <></>;
    }

    const [tooltipOpen, setTooltipOpen] = useState(projectImages.reduce((acc, curr) => (acc[curr.name] = false, acc), {}));

    const onImageClick = node => {
        overlayDiv.current.classList.remove("hidden");
        overlayDiv.current.classList.add("grid");
        overlayDiv.current.focus();

        // get current image
        const fullImage = getImageByAttr(projectImages, node.name);
        setCurrentImage({
            image: getImage(fullImage[0].childImageSharp.gatsbyImageData),
            description: fullImage[0].description,
            index: fullImage[1],
        });
    };

    const nextImage = () => {
        const nextIndex = currentImage.index + 1 === projectImages.length ? 0 : currentImage.index + 1;
        const nextImage = getImageByAttr(projectImages, nextIndex, "index");
        setCurrentImage({
            image: getImage(nextImage[0].childImageSharp.gatsbyImageData),
            description: nextImage[0].description,
            index: nextImage[1],
        });
    };

    const prevImage = () => {
        const prevIndex = currentImage.index === 0 ? projectImages.length - 1 : currentImage.index - 1;
        const prevImage = getImageByAttr(projectImages, prevIndex, "index");
        setCurrentImage({
            image: getImage(prevImage[0].childImageSharp.gatsbyImageData),
            description: prevImage[0].description,
            index: prevImage[1],
        });
    };

    const closeOverlay = ev => {
        ev.stopPropagation();
        overlayDiv.current.classList.add("hidden");
        overlayDiv.current.classList.remove("grid");
    };

    // helper for skipping clicks on children
    const stopPropagation = ev => {
        ev.stopPropagation();
    };

    const onOverlayKeyPress = ev => {
        if (ev.keyCode === 27) {
            closeOverlay(ev);
        } else if (ev.keyCode === 39 || ev.keyCode === 76) { // forward || l
            nextImage(ev);
        } else if (ev.keyCode === 37 || ev.keyCode === 72) { // back || h
            prevImage(ev);
        }
    };

    return (
        <>
            {mainImageData &&
                <div className="relative">
                    <div className="max-h-60 md:max-h-40 overflow-hidden shadow-md border hover:ring-2 ring-gray-500 cursor-pointer">
                        <GatsbyImage
                            image={getImage(mainImageData.childImageSharp.gatsbyImageData)}
                            alt=""
                            onClick={() => onImageClick(mainImageData)}
                            onMouseOver={() => setTooltipOpen({ ...tooltipOpen, [mainImageData.name]: true })}
                            onMouseOut={() => setTooltipOpen({ ...tooltipOpen, [mainImageData.name]: false })}
                        />
                    </div>
                    {mainImageData.description && tooltipOpen[mainImageData.name] && <div key={mainImageData.name} className="border shadow-lg p-3 absolute left-0 z-20 ml-48 bg-gray-100 text-sm whitespace-nowrap">
                        <div>{mainImageData.description}</div>
                    </div>}
                </div>
            }
            <ul className={"flex gap-1" + (alignCenter && " justify-center") + (className !== "" ? " " + className : "")}>
                {projectThumbnails.slice(0, previewLimit).map(n => (
                    <li key={n.name}
                        className="w-1/3 relative"
                        onClick={() => onImageClick(n)}
                        onMouseOver={() => setTooltipOpen({ ...tooltipOpen, [n.name]: true })}
                        onMouseOut={() => setTooltipOpen({ ...tooltipOpen, [n.name]: false })}
                    >
                        <div className="overflow-hidden shadow-md border hover:ring-2 ring-gray-500 cursor-pointer">
                            <GatsbyImage
                                image={getImage(n.childImageSharp.gatsbyImageData)}
                                alt=""
                                className={"object-cover " + (biggerPreview ? "h-16 md:h-36 lg:h-16" : "h-16 md:h-12")}
                                imgClassName="object-top"
                            />
                        </div>
                        {n.description && tooltipOpen[n.name] && <div key={n.name} className="border shadow-lg p-3 absolute left-0 z-20 ml-14 bg-gray-100 text-sm whitespace-nowrap">
                            <div>{n.description}</div>
                        </div>}
                    </li>
                ))}
            </ul>
            <div className="fixed top-0 left-0 min-w-full min-h-full bg-black bg-opacity-80 z-50 backdrop-filter backdrop-blur place-items-center h-screen hidden select-none" onKeyDown={onOverlayKeyPress} ref={overlayDiv} tabIndex="0" onClick={closeOverlay}>
                {currentImage.image &&
                    <ReactSwipeEvents onSwipedLeft={nextImage} onSwipedRight={prevImage} className="absolute inset-2 md:inset-20 lg:inset-x-30 lg:inset-y-10 z-50 object-contain" onClick={stopPropagation}>

                        <div className="w-full h-5/6 md:h-full relative grid grid-flow-col grid-rows-14 grid-cols-1">

                            <div className="flex flex-row-reverse items-end">
                                <XIcon className="h-7 w-7 md:h-10 md:w-10 text-white hover:text-gray-500 cursor-pointer" onClick={closeOverlay} title="Close" />
                            </div>

                            <div className="row-span-12 bg-gray-500 bg-opacity-30 p-2 relative">

                                {projectImages.length > 1 &&
                                    <div className="group w-1/3 h-full absolute top-0 left-0 z-50 p-5 items-center justify-start flex cursor-pointer" onClick={prevImage}>
                                        <ChevronDoubleLeftIcon className="h-10 w-10 md:h-20 md:w-20 text-gray-200 hidden group-hover:block" />
                                    </div>
                                }

                                <div className="h-full w-full">
                                    <GatsbyImage image={currentImage.image} alt="" className="relative h-full w-full" imgClassName="" objectFit="contain" />
                                </div>

                                {projectImages.length > 1 &&
                                    <div className="group w-2/3 h-full absolute top-0 right-0 z-50 p-5 items-center justify-end flex cursor-pointer" onClick={nextImage}>
                                        <ChevronDoubleRightIcon className="h-10 w-10 md:h-20 md:w-20 text-gray-200 hidden group-hover:block" />
                                    </div>
                                }

                            </div>

                            <div className="pt-2 text-white text-lg flex">
                                <div className="w-1/2">{currentImage.description}</div>
                                <div className="w-1/2 text-right">{currentImage.index + 1} / {projectImages.length}</div>
                            </div>

                        </div>

                    </ReactSwipeEvents>
                }
            </div>
        </>
    );
};

export default Lightbox;
