import { DotsHorizontalIcon } from "@heroicons/react/solid";
import React from "react";
import Icon from "./icon";

const TechIcons = ({ tech, limit = 100 }) => {

    if (!tech) {
        return <></>;
    }

    return (
        <>
            {tech.slice(0, limit).map(t => <Icon key={t.tech} tech={t} />)}{tech.length > limit && <DotsHorizontalIcon className="w-4 h-4 inline-block" />}
        </>
    );
};

export default TechIcons;
